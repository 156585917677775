import request from "@/utils/request";
import serviceConfig from '@/network/serviceConfig'

// 钉钉登录接口
export function dingTalkLogin(params) {
    return request({
      url: `/${serviceConfig['account-service']}/dingTalkLogin?authCode=${params}`,
      method: "get"
    });
}

// 钉钉绑定接口
export function dingTalkBind(params) {
    return request({
      url: `/${serviceConfig['account-service']}/dingTalkBind`,
      method: "post",
      data: params
    });
}


// 微信登录接口
export function weChatLogin(params) {
  return request({
    url: `/${serviceConfig['account-service']}/weChatLogin?authCode=${params}`,
    method: "get"
  });
}

// 微信绑定接口
export function weChatBind(params) {
  return request({
    url: `/${serviceConfig['account-service']}/weChatBind`,
    method: "post",
    data: params
  });
}