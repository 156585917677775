<template>
  <div style="display: flex; align-items: center; flex-direction: column">
    <div class="subtitle">{{ $t("addLogin.binding") }}</div>
    <div class="main">
      <div class="inputbox">
        <div id="space-semantic">
          <div id="embed-wrapper">
            <div class="embed-wrapper">
              <div id="captcha-element"></div>
            </div>
          </div>
        </div>
        <div class="box">
          <div class="input">
            <input
              v-model="mail"
              type="text"
              class="all email"
              :placeholder="$t('addLogin.forgetPsd.place1')"
            />
            <i class="iconfont icon-email frontIcon"></i>
          </div>
          <div class="input">
            <input
              id="code"
              v-model="code"
              :autoComplete="false"
              type="text"
              class="num"
              :placeholder="$t('addLogin.loginr.code')"
            />
            <el-button
              type="primary"
              v-if="!issend"
              class="yzm"
              @click="sendCode"
            >
              <p style="white-space: nowrap">
                {{ $t("addLogin.forgetPsd.getCode") }}
              </p>
            </el-button>
            <el-button type="primary" v-else class="yzm1">
              <p id="yzm1" style="white-space: nowrap">
                {{ $t("addLogin.forgetPsd.message6") }}
              </p>
            </el-button>
          </div>
          <div class="lobtn">
            <el-button
              class="login_goto"
              type="primary"
              :loading="btnLoading"
              @click="handleLogin"
              >{{ $t("addLogin.complete") }}</el-button
            >
            <div class="agreement-box">
              <input
                type="checkbox"
                name="agreement"
                class="agreement-check"
                v-model="checked"
              />
              <label for="agreement">
                <div class="agreement-text">
                  {{ $t("addLogin.register.agreement") }}&nbsp;
                  <el-link
                    href="https://ms.ytdevops.com/pubKnowledge/85b934dc-d50c-4d73-9f48-9d89dd07c439"
                    Target="_blank "
                    type="primary"
                    >{{ $t("addLogin.register.privacyPolicy") }}</el-link
                  >
                  &nbsp;{{ $t("addLogin.register.and") }}&nbsp;
                  <el-link
                    href="https://ms.ytdevops.com/pubKnowledge/8954eeec-f496-4f2d-84d3-ce62266d5ff7"
                    Target="_blank "
                    type="primary"
                    >{{ $t("addLogin.register.termsOfUse") }}</el-link
                  >
                </div>
              </label>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  request_verify_code,
  register_with_email,
} from "@/network/login/index.js";
import { mapMutations, mapActions } from "vuex";
import Cookies from "js-cookie";
import thirdPartyLogin from "@/components/thirdPartyLogin";
import { dingTalkBind, weChatBind } from "@/network/thirdPartyLogin/index.js";
import { setToken } from "@/utils/auth";
import { setLanguage } from "@/network/user/index.js";
import { request_joinedProjects } from "@/network/personal/index.js";
import { sessionSave, sessionRead, sessionRemove } from "@/utils/session";
import { getWatermark } from "@/network/watermask";
export default {
  components: {
    thirdPartyLogin,
  },
  data() {
    return {
      btnLoading: false,
      vercode: "",
      code: "",
      mail: "",
      issend: false,
      timeStop: "",
      checked: false,
    };
  },
  computed: {},
  mounted() {},
  beforeDestroy() {
    clearInterval(this.timeStop);
  },
  methods: {
    ...mapMutations([
      "SET_EMAIL",
      "SET_TOKEN",
      "SET_USERINFO",
      "SET_WATERMASK",
    ]),
    ...mapActions([
      "setTestPlan",
      "setVersion",
      "setSprint",
      "setBaseLine",
      "setUser",
      "setExecutionStatus",
      "setFileType",
      "setProjectUserList",
      "setStatus",
    ]),
    handleLogin() {
      const self = this;
      if (self.mail.length < 1) {
        self.$message.warning(self.$t("addLogin.loginr.place1"));
        return false;
      }
      if (self.code.length < 1) {
        self.$message.warning(self.$t("addLogin.forgetPsd.place3"));
        return false;
      }
      if (self.checked === false) {
        self.$message.warning(self.$t("addLogin.loginr.place3"));
        return false;
      }
      // 钉钉绑定
      if (localStorage.getItem('thirdparty') === 'dingTalk') {
        let data = {
          dingTalkUserInfoDto: JSON.parse(
            localStorage.getItem("dingTalkUserInfoDto")
          ),
          verifyCodeSignUpDto: {
            email: self.mail,
            password: self.mail.split("@")[0],
            verifyCode: self.code,
            inviteMark: this.$route.query.inviteMark? this.$route.query.inviteMark : "",
          },
        };
        this.btnLoading = true;
          dingTalkBind(data).then((res) => {
            setToken(res.token);
            this.SET_EMAIL(res.userInfo.email);
            this.SET_TOKEN(res.token);
            this.SET_USERINFO(res.userInfo);
            const map = {
              zh: "zh-CN",
              en: "en-US",
            };
            setLanguage({
              language: map[localStorage.getItem("lang")] || "zh-CN",
            }).then((res) => {});
            request_joinedProjects()
              .then((res) => {
                this.$store.commit("set_joined_projects", res);
                this.set_release_obj(res[0].projectId);
                sessionSave("p_id", res[0].projectId);
                setTimeout(() => {
                  const backURL = sessionRead("backURL");
                  if (
                    backURL === "null" ||
                    backURL === "" ||
                    typeof backURL === "undefined"
                  ) {
                    this.$router.push({ path: `/home` });
                  } else {
                    sessionRemove("backURL");
                    this.$router.push({ path: backURL });
                  }
                },3000);
                getWatermark().then((res) => {
                  this.SET_WATERMASK({
                    waterMaskSwitch: res.switchFlag ? true : false,
                    waterMaskValue: res.content ? res.content : "",
                  })
                  localStorage.removeItem("dingTalkUserInfoDto");
                  localStorage.removeItem("thirdparty");
              });
            })
            .catch(() => {
              this.btnLoading = false;
            });
          }).catch(() => {
            this.btnLoading = false;
          });
      }
      // 微信绑定
      else if (localStorage.getItem('thirdparty') === 'weChat') {
        let data = {
          weChatUserInfoDto: JSON.parse(
            localStorage.getItem("weChatUserInfoDto")
          ),
          verifyCodeSignUpDto: {
            email: self.mail,
            password: self.mail.split("@")[0],
            verifyCode: self.code,
            inviteMark: this.$route.query.inviteMark? this.$route.query.inviteMark : "",
          },
        };
        this.btnLoading = true;
        weChatBind(data).then((res) => {
          setToken(res.token);
          this.SET_EMAIL(res.userInfo.email);
          this.SET_TOKEN(res.token);
          this.SET_USERINFO(res.userInfo);
          const map = {
            zh: "zh-CN",
            en: "en-US",
          };
          setLanguage({
            language: map[localStorage.getItem("lang")] || "zh-CN",
          }).then((res) => {});
          request_joinedProjects()
            .then((res) => {
              this.$store.commit("set_joined_projects", res);
              this.set_release_obj(res[0].projectId);
              sessionSave("p_id", res[0].projectId);
              setTimeout(() => {
              const backURL = sessionRead("backURL");
                if (
                  backURL === "null" ||
                  backURL === "" ||
                  typeof backURL === "undefined"
                ) {
                  this.$router.push({ path: `/home` });
                } else {
                  sessionRemove("backURL");
                  this.$router.push({ path: backURL });
                }
              },3000);
              getWatermark().then((res) => {
                this.SET_WATERMASK({
                  waterMaskSwitch: res.switchFlag ? true : false,
                  waterMaskValue: res.content ? res.content : "",
                })
                localStorage.removeItem("weChatUserInfoDto");
                localStorage.removeItem("thirdparty");
            });
          })
          .catch(() => {
            this.btnLoading = false;
          });
        }).catch(() => {
          this.btnLoading = false;
        });
      }
    },
    sendCode() {
      if (this.mail.length < 1) {
        this.$message.warning(this.$t("addLogin.loginr.place1"));
        return false;
      }
      if (this.check_email()) {
        this.vercode = Math.random().toFixed(6).slice(-6);
        Cookies.set("vercode", this.vercode, {
          expires: 60000 / 864e5,
        });
        request_verify_code(this.mail, "REGISTER").then((res) => {
          this.$message({
            type: "success",
            message: this.$t("addLogin.forgetPsd.message7"),
            duration: "5000",
          });
          this.issend = true;
          let timeo = 180;
          this.timeStop = setInterval(() => {
            timeo--;
            if (timeo > 0) {
              document.getElementById("yzm1").innerText =
                this.$t("addLogin.forgetPsd.message8") +
                timeo +
                this.$t("addLogin.forgetPsd.message9");
            } else {
              timeo = 180;
              this.issend = false;
              document.getElementById("yzm1").innerText = this.$t(
                "addLogin.forgetPsd.getCode"
              );
              clearInterval(this.timeStop);
            }
          }, 1000);
        });
      }
    },
    check_email() {
      let emailRegExp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (!emailRegExp.test(this.mail)) {
        this.$message.warning(this.$t("addLogin.loginr.place1"));
        return false;
      } else {
        let point1 = this.mail.indexOf("@");
        let cop = this.mail.slice(point1).toLowerCase();
        let bidden = [
          "@163.com",
          "@126.com",
          "@qq.com",
          "@gmail.com",
          "@yeah.net",
          "@sina.com",
          "@139.com",
          "@hotmail.com",
          "@189.cn",
          "@outlook.com",
          "@sohu.com",
          "@sina.cn",
          "@vip.163.com",
          "@vip.126.com",
          "@188.com",
          "@live.cn",
          "@yahoo.com",
          "@tom.com",
          "@aliyun.com",
          "@21cn.com",
          "@msn.com",
          "@foxmail.com",
          "@live.com",
          "@letv.com",
        ];
        if (bidden.indexOf(cop) == -1) {
          return true;
        }
        this.$message.warning(this.$t("addLogin.loginr.place2"));
        return false;
      }
    },
    set_release_obj(pid) {
      let i = pid ? pid : this.get_pid();
      this.setTestPlan(i);
      this.setVersion(i);
      this.setSprint(i);
      this.setBaseLine(i);
      this.setExecutionStatus(i);
      this.setFileType(i);
      this.setProjectUserList(i);
      this.setStatus(i);
    },
  },
};
</script>

<style lang="scss" scoped>
.disabled {
  pointer-events: none;
}
.subtitle {
  width: 400px;
  font-size: 26px;
  font-weight: 700;
  text-align: start;
  color: rgba(56, 56, 56, 1);
}
.tip {
  width: 370px;
  height: 60px;
  color: white;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999; // 画布是10,topbar组件是99,关联弹窗遮>=1612
  transition: left 0.4s cubic-bezier(0.23, 1, 0.32, 1),
    top 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}
.num {
  font-size: 16px;
  padding-left: 15px;
  padding-right: 40px;
  width: 148px;
  height: 50px;
  border: 1px solid rgba(195, 195, 195, 1);
  border-radius: 6px;
  outline: none;
}
.email {
  font-size: 16px;
  padding-left: 40px;
  padding-right: 40px;
  width: 400px;
  height: 50px;
  border: 1px solid rgba(195, 195, 195, 1);
  border-radius: 6px;
  outline: none;
}
.clear {
  color: inherit;
  user-select: none;
}

.option-text {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
}
.yzm {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 50px;
  width: 239px;
  border: 1px solid rgba(48, 100, 143, 1);
  p {
    font-size: 16px;
    margin: 0;
  }
}

.yzm1 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 50px;
  width: 239px;
  border: 1px solid rgba(48, 100, 143, 1);

  //   padding-left: 10px;
  p {
    letter-spacing: 2px;
    font-size: 16px;
    margin: 0;
    margin-left: 2px;
  }
}

.main {
  width: 100%;
  display: grid;
  grid-template-columns: 100% 60%;
  // border: 2px solid white;
  border-radius: 20px;
  .inputbox {
    width: 100%;
    display: grid;
    grid-template-rows: 10% 80% 10%;
    align-items: center;
    .box {
      margin-top: 30px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 20px;
    }

    .lobtn {
      width: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .login_goto {
        font-size: 16px;
        font-weight: 700;
        word-spacing: 10px;
        border-radius: 10px;
        width: 100%;
        margin: 0px 10px;
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }

    .input {
      margin-bottom: 15px;
      width: 400px;
      position: relative;
      padding-bottom: 5px;
      display: flex;
      justify-content: space-between;
      .phone-selector {
        ::v-deep .el-input__inner {
          height: 50px !important;
          font-size: 16px;
        }
      }
    }
    .inputX {
      padding-left: 15px;
      padding-right: 40px;
      width: 480px;
      height: 50px;
      border: 1px solid rgba(195, 195, 195, 1);
      border-radius: 6px;
      outline: none;
    }
    .all {
      font-size: 16px;
    }

    input::-webkit-input-placeholder {
      padding-left: 15px;
      font-size: 16px;
      color: rgba(195, 195, 195, 1);
      letter-spacing: 2px;
    }
    .agreement-box {
      margin-top: 30px;
      display: flex;
      width: 100%;
      .agreement-check {
        width: 14px;
        height: 14px;
      }
      .agreement-text {
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
        color: black;
        text-align: left;
        ::v-deep(a) {
          font-size: 14px;
          color: rgba(48, 100, 143, 1);
        }
        ::v-deep(a:hover) {
          font-size: 14px;
          color: #409eff;
        }
      }
      ::v-deep(.el-checkbox__input) {
        margin-top: 3px;
      }
    }
  }

  .frontIcon {
    position: absolute;
    cursor: pointer;
    color: rgba(195, 195, 195, 1);
    font-size: 16px;
    margin: 25px auto;
    margin-left: 11px;
    transform: translateY(-8px);
  }
  .backIcon {
    position: absolute;
    cursor: pointer;
    right: 19px;
    color: rgba(195, 195, 195, 1);
    font-size: 16px;
    margin: 25px auto;
    transform: translateY(-8px);
  }
}
.router {
  height: 100%;
  width: 400px;
  text-align: start;
  font-size: 14px;
  display: flex;
  margin-top: 5px;
  span {
    color: rgba(137, 138, 140, 1);
  }
  p {
    margin: 0;
    color: rgba(47, 100, 181, 1);
  }
}
</style>
